import { createRouter, createWebHistory } from 'vue-router';
import HomepageFirstPage from '@/views/FrontPage.vue';
import Cases from "@/views/Cases.vue";
import AccessDenied from '@/components/AccessDenied.vue';
import LoggedInHomePage from '@/viewsloggedin/LoggedInHomePage.vue'
import LoginForm from '@/components/LoginForm.vue';
import Dashboard from '@/viewsloggedin/DashBoard.vue';
import SalesForecast from '@/viewsloggedin/SalesForecast.vue';
import Reviews from '@/viewsloggedin/Reviews.vue';
import UserProfile from '@/viewsloggedin/UserProfile.vue';
import SettingsPage from '@/viewsloggedin/SettingsPage.vue';
import AdminPage from '@/viewsloggedin/AdminPage.vue';
import store from '@/utils/store.js';

const routes = [
  {
    path: '/',
    name: 'HomepageFirstPage',
    component: HomepageFirstPage
  },
  {
    path: '/home',
    name: 'LoggedInHomePage',
    component: LoggedInHomePage,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner', 'user'], 
    }
  },
  {
    path: "/cases",
    name: "Cases",
    component: Cases,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner', 'user'], 
    }
  },
  {
    path: '/forecast',
    name: 'Forecast',
    component: SalesForecast,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner'],
    }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner', 'user'], 
    }
  },
  {
    path: '/user',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner', 'user'], 
    }
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: SettingsPage,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'owner', 'user'], 
    }
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied,
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    meta: {
      requiresAuth: true,
      roles: ['admin'], 
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  await store.dispatch('checkUser');
  const isAuthenticated = store.state.user !== null;
  const roles = to.meta.roles || []; // get the roles array from route meta

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (requiresAuth && !roles.includes(store.state.role)) {
    next('/access-denied'); // redirect if role isn't correct
  } else {
    next();
  }
});

export default router;
