<template>
  <div class="reviews">
    <h1>Reviews</h1>
    <p>This is the Reviews page. Here, users can view and leave reviews.</p>
  </div>
</template>

<script>
export default {
  name: 'ReviewsPage',
};
</script>

<style scoped>
.reviews {
  padding: 2rem;
}
</style>
