<template>
  <div class="modal">
    <div class="modal-content">
      <div id="centered">
        <div id="login-box">
          <span class="close" @click="$emit('close')">&times;</span>
          <h2>Sign in</h2>
          <form @submit.prevent="handleLogin">
            <div class="form-group">
              <label for="username">Brugernavn</label>
              <input v-model="email" type="text" id="username" required>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input v-model="password" type="password" id="password" required>
            </div>
            <button type="submit">Log ind</button>
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { auth } from '@/utils/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup(probs, context) {
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const store = useStore();
    const router = useRouter();

    const handleLogin = async () => {
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        store.dispatch('checkUser');
        router.push('/dashboard').catch(() => {});
        context.emit('close');
        context.emit('login-success');
      } catch (error) {
        errorMessage.value = error.message;
      }
    };

    return {
      email,
      password,
      errorMessage,
      handleLogin
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}

.modal-content {
  background-color: transparent;
  margin: auto;
  padding: 4rem; /* Using rem for dynamic spacing */
  width: 50%;
}

#centered {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10vh;
  height: 100vh;
}

#login-box {
  background: url('@/assets/images/tapet_gron.svg') no-repeat center center;
  background-size: 400%; /* Zoomed-in background */
  color: #e5feea;
  width: 20rem; /* Using rem for dynamic width */
  padding: 2rem; /* More dynamic padding */
  border-radius: 0.5rem; /* Border-radius in rem */
  font-family: 'Montserrat';
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.3);
}

h2 {
  text-align: center;
  margin-bottom: 2rem; /* Dynamic margin */
}

.form-group {
  margin-bottom: 1.5rem; /* Increased space between form fields */
}

label {
  display: block;
  margin-bottom: 0.8rem; /* Dynamic spacing between label and input */
}

input {
  background-color: #f2edea;
  width: 100%;
  padding: 1rem; /* Dynamic padding for input */
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* Border-radius in rem */
  color: #333;
}

button {
  background-color: #003224;
  color: #f2edea;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  border: 0.1rem ,Solid,#265445;
  border-radius: 0.3rem;
  padding: 1rem; 
  cursor: pointer;
  width: 100%;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #265445;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 2rem; /* Dynamic margin */
}

.close {
  color: #dddddd;
  float: right;
  font-size: 2rem; /* Dynamic font-size */
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
</style>
