<template>
  <div class="user-profile">
    <header class="profile-header">
      <h1>User Profile</h1>
    </header>

    <main class="profile-content">
      <!-- Basic User Info -->
      <section class="profile-section">
        <h2>Basic Information</h2>
        <div class="info-grid">
          <div class="info-item">
            <label>Display Name:</label>
            <p>{{ user?.displayName || 'Not set' }}</p>
          </div>
          <div class="info-item">
            <label>Email:</label>
            <p>{{ user?.email || 'Not available' }}</p>
          </div>
          <div class="info-item">
            <label>Role:</label>
            <p>{{ role || 'No role assigned' }}</p>
          </div>
        </div>
      </section>

      <!-- Organizations -->
      <section class="profile-section">
        <h2>Organizations & Businesses</h2>
        <div v-if="organizations.length > 0">
          <div v-for="org in organizations" :key="org.orgName" class="org-card">
            <h3>{{ org.orgName }}</h3>
            <p><strong>Default Color Scheme:</strong> {{ org.defaultColorScheme }}</p>
            
            <div class="businesses-list">
              <h4>Associated Businesses:</h4>
              <div v-for="business in org.businesses" :key="business.name" class="business-card">
                <h5>{{ business.name }}</h5>
                
                <!-- Sales Data Section -->
                <div v-if="business.data?.prevWeekSales" class="sales-data">
                  <h6>Previous Week Sales</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="{ date, sales } in getSortedSales(business.data.prevWeekSales)" 
                          :key="date">
                        <td>{{ formatDate(date) }}</td>
                        <td>{{ formatCurrency(sales) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-else>No organizations associated with this account.</p>
      </section>
    </main>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'UserProfile',
  setup() {
    const store = useStore();

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK');
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK'
      }).format(amount);
    };

    const getSortedSales = (salesData) => {
      return Object.entries(salesData)
        .map(([date, sales]) => ({ date, sales }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    return {
      user: computed(() => store.state.user),
      role: computed(() => store.state.role),
      organizations: computed(() => store.state.organizations),
      formatDate,
      formatCurrency,
      getSortedSales,
    };
  },
};
</script>

<style scoped>
.user-profile {
  padding: 2rem;
  background-color: #e5feea;
  font-family: 'Montserrat', sans-serif;
}

.profile-header {
  margin-bottom: 2rem;
}

.profile-header h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: #003224;
}

.profile-section {
  background: #F9FFFA;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.profile-section h2 {
  font-family: 'Questrial', sans-serif;
  color: #003224;
  margin-bottom: 1.5rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.info-item {
  background: white;
  padding: 1rem;
  border-radius: 4px;
}

.info-item label {
  font-weight: bold;
  color: #005432;
  display: block;
  margin-bottom: 0.5rem;
}

.org-card {
  background: white;
  padding: 1.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.org-card h3 {
  color: #003224;
  margin-bottom: 1rem;
}

.businesses-list {
  margin-top: 1rem;
}

.businesses-list ul {
  list-style: none;
  padding-left: 1rem;
}

.businesses-list li {
  padding: 0.5rem 0;
  color: #005432;
}
</style>
