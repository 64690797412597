<template>
  <div class="navbar">
    <div class="nav-top">
      <img class="logo" src="../assets/images/light_green_logomark.png" @click="$router.push('/dashboard')">
      <div class="burger-menu" @click="toggleMenu">
        <!-- Add your burger menu icon here -->
      </div>
      <nav class="navigation">
        <!-- Primary Navigation Links -->
        <router-link class="nav-link" to="/home">Home</router-link>
        <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
        <router-link class="nav-link" to="/forecast">Forecast</router-link>
        <router-link class="nav-link" to="/reviews">Reviews</router-link>

        <!-- Spacer -->
        <div class="nav-spacer"></div>

        <!-- Expandable Settings Section -->
        <div class="settings-section">
          <div class="settings-header" @click="toggleSettings">
            <span>Settings</span>
            <span v-if="!settingsExpanded">▼</span>
            <span v-else>▲</span>
          </div>
          <div v-if="settingsExpanded" class="settings-links">
            <router-link class="nav-link settings-link" to="/user">User</router-link>
            <router-link class="nav-link settings-link" to="/settings">Settings</router-link>
            <router-link class="nav-link settings-link" to="/admin">Admin</router-link>
          </div>
        </div>
      </nav>
    </div>
    
    <div class="nav-bottom">
      <button class="signout-button" @click="logout">Sign Out</button>
    </div>
  </div>
</template>

<script>
import { auth } from '@/utils/firebaseConfig.js';
export default {
  name: "UserNavbar",
  data() {
    return {
      showMenu: false,
      settingsExpanded: false, // Controls whether the settings section is expanded
    }
  },
  methods: {
    logout() {
      auth.signOut().then(() => {
        this.$store.commit('setUser', null); 
        this.$router.push({ name: 'HomepageFirstPage' });
      });
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleSettings() {
      this.settingsExpanded = !this.settingsExpanded;
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 12rem; /* Adjusted from 200px to 12rem */
  padding: 1rem;
  background-color: var(--primary-text-color);
  transition: transform 0.3s ease;
  transform: translateX(-10rem); /* Initially hidden, adjust as per requirement */
}

.navbar:hover {
  transform: translateX(0); /* Slide in on hover */
}

.nav-top, .nav-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.logo {
  width: 5rem; /* Adjusted from 80px to 5rem */
  height: 5rem; /* Adjusted from 80px to 5rem */
  margin-bottom: 2rem;
  cursor: pointer;
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nav-link {
  text-decoration: none;
  color: var(--button-text-color);
  padding: 0.5rem;
  margin: 0.5rem 0;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: var(--button-color);
  transform: scale(1.05);
}

/* Spacer between primary and secondary links */
.nav-spacer {
  flex-grow: 1;
}

/* Expandable Settings Section */
.settings-section {
  width: 100%;
}

.settings-header {
  cursor: pointer;
  color: var(--button-text-color);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-header span:first-child {
  flex-grow: 1; /* Make the "Settings" label take up available space */
}

.settings-links {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-left: 1rem; /* Indent the dropdown links */
}

.settings-link {
  margin: 0.25rem 0; /* Half the margin of regular links */
  padding-left: 0.5rem; /* Further indent the dropdown links */
}

.signout-button {
  color: var(--button-text-color);
  background-color: var(--button-color);
  border: none;
  border-radius: 0.25rem; /* Adjusted from 4px to 0.25rem */
  padding: 0.5rem;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: start;
  margin-bottom: 2rem;
}

.signout-button:hover {
  background-color: var(--button-text-color);
  color: var(--button-color);
  transform: scale(1.05);
}

.router-link-active {
  color: var(--button-color);
  font-weight: 700;
}

/* Adjust the main content to respect the space of the navbar */
.main-content {
  margin-left: 3rem; /* Adjusted margin for hidden navbar state */
  transition: margin-left 0.3s ease;
}

.navbar:hover ~ .main-content {
  margin-left: 12rem; /* Adjusted margin for visible navbar state */
}
</style>
