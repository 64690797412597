<template>
  <div class="burger-menu" @click="toggleMenu" v-bind="$attrs">
    <img src="../assets/images/burger_menu_TEST.png" alt="Menu">
  </div>
  <nav class="navigation" v-if="showMenu">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: "BurgerMenu",
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    checkViewportWidth() {
      if (window.innerWidth >= 769) {
        this.showMenu = false;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkViewportWidth);
    this.checkViewportWidth(); // Check initial width on mount
  },
  unmounted() { // Use unmounted instead of beforeUnmount
    window.removeEventListener('resize', this.checkViewportWidth);
  }
};
</script>


<style scoped>
img {
  width: 40px;
  height: 40px;
}

.burger-menu {
  cursor: pointer;
  display: none; /* Default to hidden */
}

@media screen and (max-width: 768px) {
  .burger-menu {
    display: block;
  }
}

.navigation {
  display: none; /* Hide the navigation by default */
}
</style>
