<template>
  <button
    class="green-button"
    @click="$emit('click')"
    :style="{ borderRadius: borderRadius }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'GreenButton',
  props: {
    borderRadius: {
      type: String,
      default: '50px',
    },
  },
};
</script>

<style>
.green-button {
  position: relative;
  background-color: var(--button-color);
  color: var(--button-text-color);
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.green-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--hover-color);
  transform: scaleY(0);
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.green-button:hover::before {
  transform: scaleY(1);
}
</style>
