<template>
  <div class="logged-in-home-page">
    <!-- Header Section -->
    <header class="header-section">
      <h1>Velkommen tilbage, {{ userName }}!</h1>
      <h2>Overblik over dine restaurantdata.</h2>
      <p>
        Dyk ned i dine seneste analyser og opdag nye indsigter, og træf datadrevne beslutninger for at styrke din forretning.
      </p>
    </header>

    <!-- Quick Overview Section -->
    <section class="quick-overview">
      <h2>Dagens salg</h2>
      <div class="quick-gauges">
        <div 
          v-for="business in userBusinesses" 
          :key="business.id"
          class="quick-gauge-item"
        >
          <h3>{{ business.businessData?.businessName }}</h3>
          <v-chart 
            class="quick-gauge-chart" 
            :option="getQuickGauge(business.businessData?.colorScheme?.primary)" 
            autoresize
          />
          <p class="quick-gauge-value">{{ formatCurrency(75000) }}</p>
          <p class="quick-gauge-subtitle">af {{ formatCurrency(100000) }} mål</p>
        </div>
      </div>
    </section>

    <!-- Main Content Sections -->
    <main class="main-content">
      <!-- Check if user has businesses -->
      <section v-if="userBusinesses.length > 0">
        <!-- Overview Dashboard for each business -->
        <section
          class="overview-dashboard"
          v-for="business in userBusinesses"
          :key="business.id"
        >
          <h2>Hurtigt overblik for {{ business.businessData?.businessName }}</h2>
          <h3>Se, hvordan din restaurant klarer sig.</h3>
          
          <!-- Daily Progress Gauge -->
          <div class="gauge-container">
            <div class="gauge-grid">
              <div class="gauge-item">
                <h4>Dagens omsætning</h4>
                <v-chart 
                  class="gauge-chart" 
                  :option="getDailyRevenueGauge(business.businessData?.colorScheme?.primary)" 
                  autoresize
                />
                <p class="gauge-subtitle">Sammenlignet med dagligt mål</p>
              </div>
              <div class="gauge-item">
                <h4>Ordrer i dag</h4>
                <v-chart 
                  class="gauge-chart" 
                  :option="getDailyOrdersGauge(business.businessData?.colorScheme?.primary)" 
                  autoresize
                />
                <p class="gauge-subtitle">Sammenlignet med normalt</p>
              </div>
              <div class="gauge-item">
                <h4>Kundetilfredshed</h4>
                <v-chart 
                  class="gauge-chart" 
                  :option="getCustomerSatisfactionGauge(business.businessData?.colorScheme?.primary)" 
                  autoresize
                />
                <p class="gauge-subtitle">Baseret på dagens anmeldelser</p>
              </div>
            </div>
          </div>

          <!-- Sales Bar Chart -->
          <div v-if="business.businessData?.prevWeekSales" class="sales-chart">
            <h4>Sidste 7 dages salg</h4>
            <v-chart 
              class="chart" 
              :option="getChartOption(business.businessData.prevWeekSales, business.businessData.colorScheme?.primary)" 
              autoresize
            />
          </div>

          <div class="button-container">
            <GreenButton borderRadius="8px">Se detaljeret rapport</GreenButton>
          </div>
        </section>
      </section>

      <!-- Message if user has no businesses -->
      <section v-else>
        <p>Du har ingen tilknyttede restauranter.</p>
      </section>

      <!-- Performance Insights -->
      <section class="performance-insights">
        <h2>Præstationsindsigt</h2>
        <h3>Dyk ned i din restaurants pr��stationsmålinger.</h3>
        <p>Sammenlign dine resultater med branchens standarder.</p>
        <p>Identificér områder med forbedringspotentiale.</p>
        <div class="multimedia-elements">
          <div class="infographic">[Infografik/Cirkeldiagram]</div>
        </div>
        <div class="button-container">
          <GreenButton borderRadius="8px">Få indsigt</GreenButton>
        </div>
      </section>

      <!-- Customer Feedback -->
      <section class="customer-feedback">
        <h2>Kundeoplevelser</h2>
        <h3>Se, hvad dine kunder siger.</h3>
        <p>Seneste anmeldelser: [Seneste anmeldelser]</p>
        <p>Gennemsnitlig vurdering: [Gennemsnitlig vurdering]</p>
        <div class="multimedia-elements">
          <div class="stars">⭐⭐⭐⭐⭐</div>
          <div class="carousel">[Anmeldelseskarusel]</div>
        </div>
        <div class="button-container">
          <GreenButton borderRadius="8px">Se al feedback</GreenButton>
        </div>
      </section>


      <!-- Marketing Campaigns -->
      <section class="marketing-campaigns">
        <h2>Marketingkampagner</h2>
        <h3>Planlæg og følg dine marketinginitiativer.</h3>
        <p>Aktuelle kampagner: [Kampagnedata]</p>
        <p>Resultatmålinger: [Kampagnepræstation]</p>
        <div class="multimedia-elements">
          <div class="marketing-icons">📢📢📢</div>
          <div class="progress-bar">[Fremdriftsindikator]</div>
        </div>
        <div class="button-container">
          <GreenButton borderRadius="8px">Start ny kampagne</GreenButton>
        </div>
      </section>

    </main>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import GreenButton from '../components/GreenButton.vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, GaugeChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

// Register ECharts components
use([
  CanvasRenderer,
  BarChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  GridComponent
]);

export default {
  name: 'LoggedInHomePage',
  components: {
    GreenButton,
    VChart,
  },
  setup() {
    const store = useStore();

    const userName = computed(() => store.state.user?.displayName || 'Bruger');
    const organizations = computed(() => store.state.organizations || []);

    const userBusinesses = computed(() => {
      const businesses = [];
      organizations.value.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            orgName: org.orgName,
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'short' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        maximumFractionDigits: 0
      }).format(amount);
    };

    const getSortedSales = (salesData) => {
      return Object.entries(salesData)
        .map(([date, sales]) => ({ date, sales }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    const getChartOption = (salesData, primaryColor) => {
      const sortedData = getSortedSales(salesData);
      return {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const value = params[0].value;
            return `${formatCurrency(value)}`;
          }
        },
        xAxis: {
          type: 'category',
          data: sortedData.map(item => formatDate(item.date)),
          axisLabel: {
            color: '#005432'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => formatCurrency(value),
            color: '#005432'
          }
        },
        series: [{
          data: sortedData.map(item => item.sales),
          type: 'bar',
          itemStyle: {
            color: primaryColor || '#005432'
          },
          label: {
            show: true,
            position: 'top',
            formatter: (params) => formatCurrency(params.value),
            color: '#003224'
          }
        }]
      };
    };

    const getDailyRevenueGauge = (primaryColor) => ({
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 8,
        progress: {
          show: true,
          width: 18,
          itemStyle: {
            color: primaryColor || '#005432'
          }
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 18,
            color: [[1, '#E5E5E5']]
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: true,
          offsetCenter: [0, '0%'],
          fontSize: 24,
          fontWeight: 'bold',
          formatter: '{value}%',
          color: '#003224'
        },
        data: [{
          value: 78,
          name: 'Completion'
        }]
      }]
    });

    const getDailyOrdersGauge = (primaryColor) => ({
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 8,
        progress: {
          show: true,
          width: 18,
          itemStyle: {
            color: primaryColor || '#005432'
          }
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 18,
            color: [[1, '#E5E5E5']]
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: true,
          offsetCenter: [0, '0%'],
          fontSize: 24,
          fontWeight: 'bold',
          formatter: '{value}%',
          color: '#003224'
        },
        data: [{
          value: 92,
          name: 'Completion'
        }]
      }]
    });

    const getCustomerSatisfactionGauge = (primaryColor) => ({
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 8,
        progress: {
          show: true,
          width: 18,
          itemStyle: {
            color: primaryColor || '#005432'
          }
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 18,
            color: [[1, '#E5E5E5']]
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: true,
          offsetCenter: [0, '0%'],
          fontSize: 24,
          fontWeight: 'bold',
          formatter: '{value}%',
          color: '#003224'
        },
        data: [{
          value: 95,
          name: 'Completion'
        }]
      }]
    });

    const getQuickGauge = (primaryColor) => ({
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        radius: '100%',
        progress: {
          show: true,
          width: 12,
          itemStyle: {
            color: primaryColor || '#005432'
          }
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 12,
            color: [[1, '#E5E5E5']]
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        title: {
          show: false
        },
        detail: {
          show: false
        },
        data: [{
          value: 75, // Sample value - 75%
          name: 'Sales Progress'
        }]
      }]
    });

    return {
      userName,
      userBusinesses,
      formatDate,
      formatCurrency,
      getSortedSales,
      getChartOption,
      getDailyRevenueGauge,
      getDailyOrdersGauge,
      getCustomerSatisfactionGauge,
      getQuickGauge,
    };
  },
};
</script>

<style scoped>
.logged-in-home-page {
  padding: 2rem;
  background-color: #e5feea;
  font-family: 'Montserrat', sans-serif;
}

.header-section {
  text-align: center;
  color: #003224;
  margin-bottom: 2rem;
}

.header-section h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
}

.header-section h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 1.5rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

section {
  margin-bottom: 0;
  background: #F9FFFA; 
  padding: 1rem 2rem;
  border-radius: 8px; /* Sat border-radius til 4px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Tilføjet mild skygge */
}

section h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2rem;
  color: #003224;
}

section h3 {
  font-family: 'Questrial', sans-serif;
  font-size: 1.25rem;
  color: #005432;
}

section p {
  color: #003224;
}

.multimedia-elements {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button-container {
  text-align: right;
  margin-top: 1rem;
}

.sales-chart {
  margin: 2rem 0;
  padding: 1rem;
  background: white;
  border-radius: 8px;
}

.sales-chart h4 {
  color: #003224;
  margin-bottom: 1rem;
  font-family: 'Questrial', sans-serif;
}

.chart {
  height: 300px;
  width: 100%;
}

.gauge-container {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1.5rem 0;
}

.gauge-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  align-items: center;
}

.gauge-item {
  text-align: center;
}

.gauge-item h4 {
  color: #003224;
  margin-bottom: 1rem;
  font-family: 'Questrial', sans-serif;
}

.gauge-chart {
  height: 200px;
  width: 100%;
}

.gauge-subtitle {
  color: #666;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.quick-overview {
  background: #F9FFFA;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quick-overview h2 {
  font-family: 'Questrial', sans-serif;
  color: #003224;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.quick-gauges {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  align-items: start;
}

.quick-gauge-item {
  text-align: center;
  background: white;
  padding: 0.75rem;
  border-radius: 8px;
}

.quick-gauge-item h3 {
  color: #003224;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: 'Questrial', sans-serif;
}

.quick-gauge-chart {
  height: 80px;
  width: 100%;
}

.quick-gauge-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: #003224;
  margin: 0.25rem 0 0 0;
}

.quick-gauge-subtitle {
  font-size: 0.8rem;
  color: #666;
  margin: 0.15rem 0 0 0;
}

.overview-dashboard {
  margin-bottom: 2rem;
  background: #F9FFFA;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.overview-dashboard:last-child {
  margin-bottom: 0;
}

.overview-dashboard h2 {
  margin-bottom: 0.5rem;
}

.overview-dashboard h3 {
  margin-bottom: 1rem;
}
</style>
