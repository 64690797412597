<template>
  <div class="admin-page">
    <h1>Admin Dashboard</h1>
    
    <section class="salary-targets">
      <h2>Løn Procent Mål</h2>
      
      <div v-for="business in userBusinesses" 
           :key="business.id" 
           class="business-targets"
      >
        <div class="business-header" @click="toggleBusiness(business.id)">
          <div class="header-content">
            <h3>{{ business.businessData?.businessName }}</h3>
            <div class="summary">
              <span>Gennemsnit: {{ getAverageSalaryTarget(business) }}%</span>
            </div>
          </div>
          <div class="expand-icon" :class="{ 'is-expanded': expandedBusinesses[business.id] }">
            ▼
          </div>
        </div>
        
        <div class="business-content" 
             :class="{ 'is-expanded': expandedBusinesses[business.id] }"
        >
          <div class="months-grid">
            <div v-for="month in months" 
                 :key="month.value" 
                 class="month-input"
            >
              <label :for="`${business.id}-${month.value}`">{{ month.label }}</label>
              <div class="input-group">
                <input
                  type="number"
                  :id="`${business.id}-${month.value}`"
                  :value="salaryInputs[business.id]?.[month.value] || business.businessData?.salaryTarget?.[month.value] || 0"
                  @input="updateSalaryInput(business.id, month.value, $event)"
                  min="0"
                  max="100"
                  step="0.1"
                >
                <span class="percentage">%</span>
              </div>
            </div>
          </div>
          
          <button @click="saveSalaryTargets(business.id)" class="save-button">
            Gem ændringer
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminPage',
  setup() {
    const store = useStore();
    const salaryInputs = ref({});
    const expandedBusinesses = ref({});

    const months = [
      { value: '01', label: 'Januar' },
      { value: '02', label: 'Februar' },
      { value: '03', label: 'Marts' },
      { value: '04', label: 'April' },
      { value: '05', label: 'Maj' },
      { value: '06', label: 'Juni' },
      { value: '07', label: 'Juli' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'Oktober' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' }
    ];

    const userBusinesses = computed(() => {
      const businesses = [];
      store.state.organizations.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const updateSalaryInput = (businessId, month, event) => {
      if (!salaryInputs.value[businessId]) {
        salaryInputs.value[businessId] = {};
      }
      salaryInputs.value[businessId][month] = Number(event.target.value);
    };

    onMounted(() => {
      userBusinesses.value.forEach(business => {
        if (!salaryInputs.value[business.id]) {
          salaryInputs.value[business.id] = {};
        }
        months.forEach(month => {
          salaryInputs.value[business.id][month.value] = 
            business.businessData?.salaryTarget?.[month.value] || 0;
        });
      });
    });

    const saveSalaryTargets = async (businessId) => {
      const businessTargets = salaryInputs.value[businessId];
      for (const [month, percentage] of Object.entries(businessTargets)) {
        await store.dispatch('updateSalaryTarget', {
          businessId,
          month,
          percentage: Number(percentage)
        });
      }
    };

    const toggleBusiness = (businessId) => {
      expandedBusinesses.value[businessId] = !expandedBusinesses.value[businessId];
    };

    const getAverageSalaryTarget = (business) => {
      const targets = business.businessData?.salaryTarget || {};
      const values = Object.values(targets);
      if (values.length === 0) return 0;
      const sum = values.reduce((acc, val) => acc + val, 0);
      return (sum / values.length).toFixed(1);
    };

    return {
      months,
      userBusinesses,
      salaryInputs,
      saveSalaryTargets,
      updateSalaryInput,
      expandedBusinesses,
      toggleBusiness,
      getAverageSalaryTarget,
    };
  }
};
</script>

<style scoped>
.admin-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-page h1 {
  color: #003224;
  margin-bottom: 2rem;
  font-family: 'Montserrat', sans-serif;
}

.business-targets {
  background: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.business-targets:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.business-header {
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  transition: background-color 0.2s ease;
}

.business-header:hover {
  background: #f9fffa;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header-content h3 {
  margin: 0;
  color: #003224;
  font-size: 1.2rem;
}

.summary {
  color: #666;
  font-size: 0.9rem;
}

.expand-icon {
  color: #005432;
  transition: transform 0.3s ease;
  font-size: 0.8rem;
}

.expand-icon.is-expanded {
  transform: rotate(180deg);
}

.business-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: white;
}

.business-content.is-expanded {
  max-height: 1000px;
  padding: 0 1.5rem 1.5rem;
}

.months-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.month-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.month-input label {
  color: #666;
  font-size: 0.9rem;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f5f5f5;
  padding: 0.5rem;
  border-radius: 6px;
}

input {
  width: 70px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s ease;
}

input:focus {
  outline: none;
  border-color: #005432;
}

.percentage {
  color: #666;
  font-size: 0.9rem;
}

.save-button {
  background-color: #005432;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 0.9rem;
}

.save-button:hover {
  background-color: #004428;
}

@media (max-width: 768px) {
  .admin-page {
    padding: 1rem;
  }
  
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .months-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
</style>
