<template>
  <div class="dashboard">

    <div class="report-embed">
      <iframe 
        width="100%" 
        height="100%" 
        src="https://lookerstudio.google.com/embed/reporting/d49af4c8-fbdb-4013-aba4-4d6d9d196d45/page/ZU6uD" 
        frameborder="0" 
        style="border:0;" 
        allowfullscreen 
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  </div>
</template>


<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  padding: 1rem;
}

.filter-section {
  margin-bottom: 2rem;
}

.report-embed {
  width: 100%;
  height: 80vh; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
