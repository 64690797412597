<template>
  <div class="container">

    <header>
      <h1>Databite Analytics</h1>
      <p>Your go-to source for restaurant insights</p>
    
      <section class="intro">
        <p>Welcome to Databite Analytics! Dive deep into actionable data to understand customer behavior, optimize your menu, and maximize profits.</p>

      <section class="features">
        <div class="feature">
          <h2>Customer Insights</h2>
          <p>Know your customers better. Discover their preferences, peak dining hours, and return frequency.</p>
        </div>

        <div class="feature">
          <h2>Menu Analysis</h2>
          <p>Find out which dishes are the most popular, the most profitable, and the ones that need a tweak.</p>
        </div>

        <div class="feature">
          <h2>Performance Tracking</h2>
          <p>Track your growth, monitor sales, and evaluate campaigns with our easy-to-read graphs and charts.</p>
        </div>
      </section>

      <section class="cta">
        <p>Join thousands of restaurants that trust Databite Analytics. Start your data journey today!</p>
        <button>Get Started</button>
      </section>

    <footer>
      <p>&copy; 2023 Databite Analytics - Making Restaurants Smarter</p>
    </footer>
    </section>
  </header>
  </div>
</template>

<script>
export default {
  name: "HomepageFirstPage",
};
</script>

<style scoped>
header {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  font-weight: 700;
  font-size: 2.5em;
  padding-top: 5px;
}

.intro {
  margin: 20px 0;
  text-align: center;
}

.features {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.feature {
  flex: 1;
  padding: 10px;
  text-align: center;
  background-color: var(--hover-color);
  color: var(--button-text-color);
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.cta {
  text-align: center;
  margin: 40px 0;
}

button {
  padding: 10px 20px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--hover-color);
}

footer {
  text-align: center;
  padding-top: 20px;
}
/* For screens smaller than 768px */
@media screen and (max-width: 768px) {
  .features {
    flex-direction: column;
  }

  .feature {
    margin: 2% 15%;
    
  }

  h1 {
    font-size: 1.5em;
  }
}

</style>
