<template>
  <div class="settings_page">
    <h1>Settings</h1>
    <p>This is the Settings page. Users can adjust their preferences and account settings here.</p>
  </div>
</template>

<script>
export default {
  name: 'SettingsPage',
};
</script>

<style scoped>
.settings_page {
  padding: 2rem;
}
</style>
