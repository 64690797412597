<template>
  <div>
    <section class="hero">
      <h1>Succeshistorier og Cases</h1>
      <p>Hvordan datadrevet indsigt transformerer restaurationsbranchen</p>
    </section>
    <section class="content">
      <p>
        Velkommen til vores samling af succeshistorier og cases. Her deler vi
        inspirerende eksempler på, hvordan restauranter har opnået
        ekstraordinære resultater ved hjælp af data og analyser.
      </p>
      <div class="case">
        <h2>Optimeret Menu og Øget Indtjening</h2>
        <p>
          En restaurant i København anvendte dataanalyse til at identificere
          deres mest populære retter og justerede deres menu derefter. Resultatet
          var en stigning i indtjeningen med 15% på kun tre måneder.
        </p>
        <a href="https://eksempel.dk/case1">Læs mere</a>
      </div>
      <div class="case">
        <h2>Reduceret Madspild og Forbedret Effektivitet</h2>
        <p>
          En restaurantkæde brugte data til at overvåge deres lagerbeholdning i
          realtid. Dette førte til en betydelig reduktion i madspild og en
          forbedring af deres driftseffektivitet.
        </p>
        <a href="https://eksempel.dk/case2">Læs mere</a>
      </div>
      <div class="case">
        <h2>Forbedret Kundeloyalitet gennem CRM</h2>
        <p>
          Ved at anvende en CRM-løsning kunne en restaurant segmentere deres
          kundebase og målrette deres markedsføring mere effektivt. Resultatet
          var en stigning i kundeloyalitet og gentagne besøg.
        </p>
        <a href="https://eksempel.dk/case3">Læs mere</a>
      </div>
    </section>
    <section class="content">
      <p>Er du klar til at transformere din restaurant med data og analyser?</p>
      <button class="cta-button">Kontakt os</button>
    </section>
    <footer class="footer">
      <img src="@/assets/images/green_logomark.png" alt="Databite Logomark" />
      <p>
        <a href="/">Home</a> | <a href="/services">Services</a> |
        <a href="/contact">Contact</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "CasesPage",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Questrial&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #e5feea;
  color: #003224;
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-family: "Questrial", sans-serif;
}

.header,
.footer {
  text-align: center;
  padding: 20px;
}

.hero {
  background-image: url("@/assets/images/tapet_gron.svg");
  text-align: center;
  padding: 50px 20px;
}

.content {
  padding: 20px;
}

.case {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #005432;
  color: #f2edea;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #003224;
  transform: translateY(-5px);
}
</style>