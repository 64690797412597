<template>
  <div class="modal">
    <div class="modal-content">
      <div id="centered">
        <div id="access-denied-box">
          <span class="close" @click="handleClose">&times;</span>
          <h2>Access Denied</h2>
          <p>You do not have permission to access this page.</p>
          <button @click="handleRedirect">Return to Home</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup(props, context) {
    const router = useRouter();

    const handleRedirect = () => {
      router.push('/').catch(() => {});
      context.emit('close');
    };

    const handleClose = () => {
      context.emit('close');
    };

    return {
      handleRedirect,
      handleClose
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}

.modal-content {
  background-color: transparent;
  margin: auto;
  padding: 4rem;
  width: 50%;
}

#centered {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10vh;
  height: 100vh;
}

#access-denied-box {
  background: url('@/assets/images/tapet_gron.svg') no-repeat center center;
  background-size: 400%;
  color: #e5feea;
  width: 20rem;
  padding: 2rem;
  border-radius: 0.5rem;
  font-family: 'Montserrat';
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.3);
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

p {
  text-align: center;
  margin-bottom: 2rem;
}

button {
  background-color: #003224;
  color: #f2edea;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  border: 0.1rem solid #265445;
  border-radius: 0.3rem;
  padding: 1rem;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #265445;
}

.close {
  color: #dddddd;
  float: right;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
}
</style>
